@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url('files/ProximaNova-Regular.woff2') format('woff2'),
    url('files/ProximaNova-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova Semibold';
  font-style: normal;
  font-weight: 500;
  src: url('files/ProximaNova-Semibold.woff2') format('woff2'),
    url('files/ProximaNova-Semibold.woff2') format('woff');
}

@font-face {
  font-family: 'Proxima Nova Bold';
  font-style: normal;
  font-weight: 700;
  src: url('files/ProximaNova-Bold.woff2') format('woff2'), url('files/ProximaNova-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Majesti Banner';
  font-style: normal;
  font-weight: 400;
  src: url('files/Majesti-Banner-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'Majesti Banner';
  font-style: normal;
  font-weight: 700;
  src: url('files/Majesti-Banner-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('files/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins Bold';
  font-style: normal;
  font-weight: 700;
  src: url('files/Poppins-Bold.ttf') format('truetype');
}
