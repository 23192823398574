.ant-modal-content {
  background-color: #041c2c;
  border-radius: 8px;
}

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #cd796e;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  color: #ffffff;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  color: #e6e7e8;
}

.ant-modal-confirm-btns .ant-btn {
  padding: 6px 24px;
  border-radius: 4px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  text-transform: uppercase;
}

.ant-modal-confirm-btns .ant-btn.ant-btn-default {
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: transparent;
  margin-bottom: 8px;
}

.ant-modal-confirm-btns .ant-btn.ant-btn-default:hover,
.ant-modal-confirm-btns .ant-btn.ant-btn-default:focus {
  background: #ffffff;
  color: #020e16;
  border: 1px solid #ffffff;
}

.ant-modal-confirm-btns .ant-btn.ant-btn-primary {
  background: #964439;
  color: #ffffff;
  /* text-shadow: 0px 1px 0px #f3efea; */
  border: none;
}

.ant-modal-confirm-btns .ant-btn.ant-btn-primary:hover,
.ant-modal-confirm-btns .ant-btn.ant-btn-primary:focus {
  background: #cd796e;
  color: #ffffff;
}
.ant-modal-confirm .ant-modal-body {
  padding: 24px;
}
.ant-modal-confirm-warning .ant-modal-confirm-btns .ant-btn.ant-btn-primary,
.ant-modal-confirm-warning .ant-modal-confirm-btns .ant-btn.ant-btn-primary:hover,
.ant-modal-confirm-warning .ant-modal-confirm-btns .ant-btn.ant-btn-primary:focus {
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: transparent;
}

/* POPOVER */
.ant-popover-inner {
  background-color: #f3efea;
  border-radius: 5px;
}
.ant-popover-arrow-content {
  --antd-arrow-background-color: #f3efea;
}

.customModalStyle {
  padding: 32px;
}

/* chevron styles */
.ant-select .ant-select-arrow svg {
  display: none;
}
.ant-select .ant-select-arrow .anticon::after {
    content: '';
    width: 20px;
    height: 20px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEKSURBVHgB7djBCcJAFEXRJzZgCb8ES5gy3GkJlmIHtmAHlqId6NKdzqAfoqiJZgIZ5h54ZKfhJmQxEgAAAAAAAAAAAADgu6mGE+LmcZe4s4Yxi1s8rkcVIt3sPu7a2FL5pfinxn8c4kwFWOs5zhCRXuP4tirA69uTO1LQ+zj+Fo3eRp8D9Y20bPntvQpguj/J3JHa4qQFFcKUN1KXOCsVxpQnUluc9D0KKpSpX6QuceYqnOm/SFXEcabfIlUVx5m6RaoyjjO1R6o2jjP9F6mKOM70W6Sq4jhTt0hVxnGm75GqjuNM7yMRp8H0HOmgkRx+TTQe6SQyPK47DXdMCwAAAAAAAAAAAADI6gb0q/QsSrv45wAAAABJRU5ErkJggg==");
}
